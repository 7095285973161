import styled from "styled-components";
import { useTranslation } from "@pancakeswap/localization";
import Head from "next/head";
import { useRouter } from "next/router";
import { DEFAULT_META, getCustomMeta } from "config/constants/meta";
import { useCakeBusdPrice } from "hooks/useBUSDPrice";
import Container from "./Container";

const StyledPage = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

export const PageMeta: React.FC<
  React.PropsWithChildren<{ symbol?: string }>
> = ({ symbol }) => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation();
  const { pathname } = useRouter();
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true });
  const cakePriceUsdDisplay = cakePriceUsd
    ? `$${cakePriceUsd.toFixed(3)}`
    : "...";

  const pageMeta = getCustomMeta(pathname, t, locale) || {};
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta };
  let pageTitle = cakePriceUsdDisplay
    ? [title, cakePriceUsdDisplay].join(" - ")
    : title;
  if (symbol) {
    pageTitle = [symbol, title].join(" - ");
  }

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <link
        href="https://unpkg.com/nes.css@latest/css/nes.min.css"
        rel="stylesheet"
      />
    </Head>
  );
};

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  symbol?: string;
}

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({
  children,
  symbol,
  ...props
}) => {
  return (
    <>
      <PageMeta symbol={symbol} />
      <StyledPage {...props}>{children}</StyledPage>
    </>
  );
};

export default Page;
