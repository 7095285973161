import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
} from "@pancakeswap/uikit";
import { ContextApi } from "@pancakeswap/localization";
import { nftsBaseUrl } from "views/Nft/market/constants";
import { perpLangMap } from "utils/getPerpetualLanguageCode";
import { perpTheme } from "utils/getPerpetualTheme";
import { DropdownMenuItems } from "@pancakeswap/uikit/src/components/DropdownMenu/types";
import { SUPPORT_ONLY_BSC } from "config/constants/supportChains";

export type ConfigMenuDropDownItemsType = DropdownMenuItems & {
  hideSubNav?: boolean;
};
export type ConfigMenuItemsType = Omit<MenuItemsType, "items"> & {
  hideSubNav?: boolean;
  image?: string;
} & {
  items?: ConfigMenuDropDownItemsType[];
};

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item;
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item;
  }
  return {
    ...item,
    disabled: true,
  };
};

const config: (
  t: ContextApi["t"],
  isDark: boolean,
  languageCode?: string,
  chainId?: number
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t("Trade"),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: "/swap",
      showItemsOnMobile: false,
      items: [
        {
          label: t("Swap"),
          href: "/swap",
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },

    {
      label: "Information",
      href: "#",
      icon: MoreIcon,
      hideSubNav: true,
      type: DropdownMenuItemType.EXTERNAL_LINK,
      items: [
        {
          label: t("Website"),
          href: "https://seahorse.eco/",
          supportChainIds: SUPPORT_ONLY_BSC,
        },

        {
          type: DropdownMenuItemType.DIVIDER,
        },
        {
          label: t("Community"),
          href: "https://t.me/SeahorseChain",
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t("Docs"),
          href: "https://docs.seahorse.eco/",
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId));

export default config;
